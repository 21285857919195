import React, { useContext } from "react";
import Icons from "../../assets/svg/Icons";
import Row from "../Layout/Row";
import { ModalContext } from "../Router/Router";

const Modal = ({ isModalOpen, title, children, hasFooter, xxlModal }) => {
  const { modalOpen } = useContext(ModalContext);
  return (
    <div
      className="modal__layout"
      style={isModalOpen ? { display: "block" } : { display: "none" }}
    >
      <div className="modal__layout-centered">
        <div className={`modal ${xxlModal ? "modal__large" : ""}`}>
          
          {title ? (
            <div className="modal__header">
              <h2>{title}</h2>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  modalOpen(false);
                }}
              >
                <Icons.HamburgerClose />
              </a>
            </div>
          ) : (
            ""
          )}
          
          <div className="modal__content">
            {children}
          </div>

          {hasFooter ? (
            <div className="modal__footer">
              <Row justify={["xsSpaceBetween"]}>
                <button
                  className="--button-gray"
                  onClick={() => {
                    modalOpen(false);
                  }}
                >
                  Mégsem
                </button>
                
                <button
                  className="--button-green"
                  type="submit"
                  form="modalForm"
                >
                  Mentés
                </button>
              </Row>
            </div>
          ) : (
            ""
          )}
          
        </div>
      </div>
    </div>
  );
};

export default Modal;

import { proxy } from "valtio";

// Linked Stores
import StoreUser from "./User";
import StoreDomain from "./Domain";
import StoreRequests from "./Requests";


// GLOBAL STORE
const StoreGlobal = proxy({
  userLoggedIn: false,
  forgottenSent: false,
  user: StoreUser,
  domain: StoreDomain,
  requests: StoreRequests,
});


// Set user logged in
export const SetUserLoggedIn = (state) => {
  StoreGlobal.userLoggedIn = state;
  if (state) {
    SetForgottenSent(false);
  }
};

// Set user logged in
export const SetForgottenSent = (state) => {
  StoreGlobal.forgottenSent = state;
};

export default StoreGlobal;
// Components import
import Icons from "../../assets/svg/Icons";

const TableArrow = (props) => {
  return (
    <div
      className="tableArrow"
      title={`Megnyitás a térképen SVP-${props.id}`}
    >
      <Icons.ArrowForward />
    </div>
  );
};

export default TableArrow;
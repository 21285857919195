import Icons from "../../assets/svg/Icons";


const SearchBar = ({placeholder, filterText, setFilterText}) => {

	const handleClear = () => {
    if (filterText) {
      setFilterText('');
    }
  };

	return (
		<div className="search">
			<div className="searchInput">
				<div className="searchIcon">
					<Icons.Search />
				</div>
				<input
					type="text"
					placeholder={placeholder}
					aria-label={placeholder}
					value={filterText}
					onChange={e => setFilterText(e.target.value)}
				/>
				<div
					className={`closeIcon ${filterText ? "icon-visible" : ""}`}
					onClick={handleClear}
				>
					<Icons.HamburgerClose />
				</div>
			</div>
		</div>
	);
}

export default SearchBar;

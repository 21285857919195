// React
import { useState, useEffect } from "react";
// Component Imports
import Table from "../../components/Table/Table";
import TableRequests from "../../components/Table/Tables/TableRequests";
import CardRequests from "../../components/Cards/CardRequest";
// Export to CSV
import { ExportToCsv } from "export-to-csv-fix-source-map";
// State imports from Store
import { useSnapshot } from "valtio";
import StoreGlobal from "../../data/store/Global";
import StoreRequests from "../../data/store/Requests";
import TableEmpty from "../../components/Table/TableEmpty";


const Requests = () => {
  const { requests } = useSnapshot(StoreRequests);
  const { domain_name } = useSnapshot(StoreGlobal.domain);
  const [ csvData, setCsvData ] = useState([]);

  // Filtering
  const [filterText, setFilterText] = useState('');
  const filterableColums = [
    "id",
    "created_at",
    "resolved_addr",
  ];

  // Filter function
  const filteredData = requests && requests.filter((item) => {
    for (const key of filterableColums) {
      if (item[key] && item[key].toString().toLowerCase().includes(filterText.toLowerCase())) {
        return item;
      }
    };
    return null;
  });

  // Transform data to CSV formating
  useEffect(() => {
    if (requests) {

      let csvDataArray = [];

      const buildingFeatureNames = [
        "Épületmodell",
        "Épületmodell tetőobjektumokkal",
        "Épületmodell tetőobjektumokkal és napelem kiosztással",
      ];

      const statusTranslate = {
        Pending: "Feldolgozás alatt",
        Available: "Elérhető",
        Denied: "Nem teljesíthető",
      };

      requests.map((request) => {
        let requestCSVObj = {
          id: `SVP-${request.id}`,
          created_at: request.created_at,
          resolved_addr: request.resolved_addr,
          coords: `${request.coords.lat}, ${request.coords.lng}`,
          service: request.service,
          building_type: request.building_type,
          building_features: buildingFeatureNames[request.building_features],
          status: statusTranslate[request.status],
          url: `http://map.solarvp.hu/?svp=${request.id}`,
        }
        return csvDataArray.push(requestCSVObj);
      });

      setCsvData(csvDataArray);
    }
  }, [requests]);

  // CSV options
  const date = new Date();
  const csvOptions = {
    filename: `SolarVP_Adatigények-${domain_name}-${date.getFullYear()}_${date.getMonth()+1}_${date.getDate()}`,
    showLabels: true,
    useTextFile: false,
    useBom: true,
    headers: ["Azonosító", "Igénylés dátuma", "Cím", "Koordináták", "Szolgáltatás", "Épület típusa", "Igényelt adatok", "Státusz", "URL"]
  };
  const csvExporter = new ExportToCsv(csvOptions);

  return (
    <Table
      title="Megvásárolt téradatai"
      filterText={filterText}
      setFilterText={setFilterText}
      action={() => csvExporter.generateCsv(csvData)}
      actionLabel="CSV"
    >
      {requests.length !== 0 ? (
        <>
          <TableRequests data={filteredData} />
          <CardRequests data={filteredData} />
        </>
      ):(
        <TableEmpty
          headline="Nincsenek megvásárolt téradai!"
          message="A térkép felület használatával percek alatt leadhatja igényeit amelyek állapotát itt nyomonkövetheti."
        />
      )}
    </Table>
  );
};

export default Requests;

// Component imports
import SearchBar from "../SearchBar/SearchBar";
import Button from "../Button/Button";


// React Data Table Defaults
export const tableBreakpoints = {
  TL: 980,
  DS: 1200,
  DL: 1568,
};
export const columWidth = {
  small: "4rem",
  medium: "8rem",
  normal: "10rem",
  large: "12rem",
  dateCount: "6rem",
  dateDate: "9rem",
  dateTime: "11rem",
  arrow: "4.5rem",
};
export const paginationComponentOptions = {
  rangeSeparatorText: '/',
  selectAllRowsItem: true,
  noRowsPerPage: true,
};


const Table = ({
  title,
  children,
  action = false,
  actionLabel,
  filterText,
  setFilterText
}) => {
  return (
    <section className="datatable">
      <div className="datatable__header">
        <h2>{title}</h2>
        <div className="datatable__header__actions">
          <SearchBar
            placeholder="Keresés..."
            filterText={filterText}
            setFilterText={setFilterText}
          />
          {action &&
            <Button
              buttonType="neutral"
              actionType="button"
              onClickEvent={action}
            >
              {actionLabel}
            </Button>
          }
        </div>
      </div>
      {children}
    </section>
  );
};

export default Table;

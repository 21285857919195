// API URL
import { urls } from "../urls";
// Cookie
import { cookieGetToken } from "../cookie";
// Check error
const CheckError = (response, message, value) => {
  if (response.status === 200) {
    if(value === "admin") message(["success", "Felhasználó admin jogosultságot kapott."]);
    if(value === "user") message(["success", "Admin lefokozva felhasználóvá."]);
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma történt."]);
    throw Error(response.statusText);
  }
};

// Convert user to admin or admin to user
const convertUserRole = async (value, id, message) => {
  await fetch(`${urls.convertUser}${id}/to_${value}`, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response, message, value))
    .catch((error) => console.log(error));
};

export default convertUserRole;

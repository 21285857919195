// Import
import { urls } from "../urls";

// Store
import { SetForgottenSent } from "../../data/store/Global";

// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", "A megadott e-mail cím nincsen regisztrálva!"]);
    throw Error(response.statusText);
  }
};

// Forgotten password
const postForgotPassword = async (body, message) => {
  await fetch(urls.forgotPassword, {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: body.email,
    }),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      SetForgottenSent(true);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default postForgotPassword;

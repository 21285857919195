// React
import { useState, useEffect } from "react";

// Components
import Modal from "../Modal";
import Input from "../../Input/Input";
import InputPassword from "../../Input/InputPassword";

// Toast
import { useToastContext } from "../../Toast/Toast";

// State imports from Store
import { useSnapshot } from "valtio";
import StoreGlobal from "../../../data/store/Global";

// API import
import patchProfile from "../../../api/user/patchProfile";
import patchPassword from "../../../api/password/patchPassword";



const ModalProfile = ({ isModalOpen }) => {
  const { first_name, last_name, email, phone } = useSnapshot(StoreGlobal.user);
  const addToast = useToastContext();

  // Form Data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    currentPass: "",
    newPass: "",
  });

  // Populate form data with stored user data
  useEffect(() => {
    setFormData(prevData => {
      return {
        ...prevData,
        firstName: first_name,
        lastName: last_name,
        email: email,
        phone: phone,
      }
    })
  }, [first_name, last_name, email, phone]);

  // Handle input change
  const handleInput = (e) => {
    setFormData(prevData => {
      const {name, value, type, checked} = e.target
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value
      }
    })
  };

  // Handle form submition
  const handleSubmit = (e) => {
    e.preventDefault();

    // Update profile if data has changed
    if (
      formData.firstName !== first_name ||
      formData.lastName !== last_name ||
      formData.email !== email ||
      formData.phone !== phone
    ) {
      // Create body
      let profileBody = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        phone: formData.phone
      };
      // Initialize API
      patchProfile(profileBody, addToast);
    }

    // Update pasword if fields are filled
    if (formData.currentPass && formData.newPass) {
      // Create body
      let passwordBody = JSON.stringify({
        old_password: formData.currentPass,
        new_password: formData.newPass,
      });
      // Create cookie update body
      let cookieBody = {
        email: formData.email,
        password: formData.newPass,
      };
      // Initialize API
      patchPassword(passwordBody, cookieBody, addToast);
    }
  };

  return (
    <Modal isModalOpen={isModalOpen} title={"Profil beállítások"} hasFooter={true}>
      <form id="modalForm" onSubmit={handleSubmit}>

        <div className="modal__content__section">
          <h4>Személyes adatok</h4>
          <div className="modal__content__section__group">
            <div className="modal__content__section__group-inline">
              <Input
                label={"Vezetéknév"}
                type={"text"}
                name={"lastName"}
                onChange={handleInput}
                value={formData.lastName}
              />
              <Input
                label={"Keresztnév"}
                type={"text"}
                name={"firstName"}
                onChange={handleInput}
                value={formData.firstName}
              />
            </div>
            <Input
              label={"Email"}
              type={"email"}
              name={"email"}
              onChange={handleInput}
              value={formData.email}
            />
            <Input
              label={"Telefonszám"}
              type={"tel"}
              name={"phone"}
              onChange={handleInput}
              value={formData.phone}
            />
          </div>
        </div>

        <div className="modal__content__section">
          <h4>Jelszó módosítása</h4>
          <div className="modal__content__section__group">
            <InputPassword
              label={"Aktuális jelszó"}
              name={"currentPass"}
              onChange={handleInput}
              value={formData.currentPass}
            />
            <InputPassword
              label={"Új jelszó"}
              name={"newPass"}
              onChange={handleInput}
              value={formData.newPass}
            />
          </div>
        </div>

      </form>
    </Modal>
  );
};

export default ModalProfile;

import React from "react";

const Progressbar = ({ max, current }) => {
  const calculateProgress = (max, current) => {
    let percent = (current / max) * 100;
    return percent;
  };
  return (
    <div className="progress">
      <span style={{ width: `${calculateProgress(max, current)}%` }}></span>
    </div>
  );
};

export default Progressbar;

import { GaInit } from "./api/ga";
import Header from "./components/Layout/Header";
import MainRouter from "./components/Router/Router";

const App = () => {

  // Initialize Google Analytics
  GaInit();

  return (
    <MainRouter>
      <Header />
    </MainRouter>
  );
};

export default App;
import Icons from "../../assets/svg/Icons";

const TableNoResults = (props) => {
  return (
    <div className="datatable__noresults">
      <div className="datatable__noresults__icon">
        {props.icon || <Icons.SadPin /> }
      </div>
      <h5>{props.text}</h5>
    </div>
  );
};

export default TableNoResults;

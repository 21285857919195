import Row from "../../components/Layout/Row";

const BlankPage = () => {
  return (
    <section className="blank-page">
      <Row>
        <div className="--col-xs-12">
          <h2>Általános szerződési feltételek</h2>
        </div>
      </Row>
      <Row>
        <div className="--col-xs-12 --col-xxl-12 blank-page__subtitle">
          <h3>What Are Terms and Conditions?</h3>
        </div>
        <div className="--col-xs-12 --col-xxl-7">
          <p>
            A terms and conditions agreement outlines the website
            administrator’s rules regarding user behavior and provides
            information about the actions the website administrator can and will
            perform.
          </p>

          <p>
            Essentially, your terms and conditions text is a contract between
            your website and its users. In the event of a legal dispute,
            arbitrators will look at it to determine whether each party acted
            within their rights.
          </p>

          <p>
            Creating the best terms and conditions page possible will protect
            your business from the following:
          </p>
        </div>
        <div className="--col-xs-12 --col-xxl-7 blank-page__list">
          <ul>
            <li>
              Abusive users: Terms and Conditions agreements allow you to
              establish what constitutes appropriate activity on your site or
              app, empowering you to remove abusive users and content that
              violates your guidelines.
            </li>
            <li>
              Intellectual property theft: Asserting your claim to the creative
              assets of your site in your terms and conditions will prevent
              ownership disputes and copyright infringement.
            </li>
            <li>
              Potential litigation: If a user lodges a legal complaint against
              your business, showing that they were presented with clear terms
              and conditions before they used your site will help you immensely
              in court.
            </li>
          </ul>
          <p className="blank-page__quote">
            In short, terms and conditions give you control over your site and
            legal enforcement if users try to take advantage of your operations.
          </p>
        </div>
        <div className="--col-xs-12 --col-xxl-12 blank-page__subtitle">
          <h3>Are You Legally Required to Have Terms and Conditions?</h3>
        </div>
        <div className="--col-xs-12 --col-xxl-7">
          <p>
            Technically, no. You aren’t legally required to have a terms and
            conditions agreement.
          </p>

          <p>
            However, having terms and conditions for websites is considered
            standard business practice in the US, Canada, the UK, and just about
            everywhere else — from South Africa to Australia.
          </p>

          <p>
            If you plan to grow your business or expand your user base, a simple
            website terms and conditions page will provide your site with an
            additional layer of legal protection.
          </p>
        </div>
      </Row>
    </section>
  );
};

export default BlankPage;

import { useContext } from "react";
import Progressbar from "../../components/Progressbar/Progressbar";
import Icons from "../../assets/svg/Icons";
import CardServices from "../../components/Cards/CardService";
import Accordion from "../../components/Accordion/Accordion";
import imgTeto from "../../assets/images/szolar-thumbnail.jpg"
import Button from "../../components/Button/Button";

// State imports from Store
import { useSnapshot } from "valtio";
import StoreGlobal from "../../data/store/Global";
import { ModalContext } from "../../components/Router/Router";

const Services = [
  {
    title: "SolarVP 3D",
    img: imgTeto,
    link: "https://map.solarvp.hu",
  }
];

const DashboardServices = () => {
  const { requests_length, last_request } = useSnapshot(StoreGlobal.requests);
  
  return (
    <div className="dashboard__services">
      <h4>Elérhető szolgáltatásai</h4>
      <div className="dashboard__services-list">
        {Services.map((element, index) => {
          return (
            <CardServices
              key={index}
              title={element.title}
              img={element.img}
              dataPurchased={requests_length}
              lastQuery={last_request.created_at}
              badgeStatus={last_request.status}
              link={element.link}
            />
          );
        })}
      </div>
    </div>
  );
};

const DashboardDetails = () => {
  const { first_name, last_name, is_admin } = useSnapshot(StoreGlobal.user);
  const { domain_name, on_trial } = useSnapshot(StoreGlobal.domain);
  const { requests_length } = useSnapshot(StoreGlobal.requests);
  const { modalOpen, modalType } = useContext(ModalContext);


  return (
    <div className="dashboard__details">
      
      <Accordion>
        <div className="dashboard__details__header">
          <h4>Profil</h4>
          <div className="dashboard__details__header--chevron">
            <Icons.ArrowDown color={"green"} />
          </div>
          <div className="dashboard__details__header--action">
            <Button
              asLink={"true"}
              onClickEvent={(e) => {
                e.preventDefault();
                modalOpen(true);
                modalType("profile");
              }}
            >
              Profil beállítások
            </Button>
          </div>
        </div>
        <div>
          <div className="dashboard__details__content">
            <div className="dashboard__details__content--block">
              <h3>{last_name} {first_name}</h3>
              <span className="span--bold">
                {is_admin ? "Adminisztrátor" : "Felhasználó"}
              </span>
            </div>
          </div>
        </div>
      </Accordion>

      <hr/>
      
      <Accordion>
        <div className="dashboard__details__header">
          <h4>Domain</h4>
          <div className="dashboard__details__header--chevron">
            <Icons.ArrowDown color={"green"} />
          </div>
          <div className="dashboard__details__header--action">
            {is_admin && (
              <Button
                asLink={"true"}
                onClickEvent={(e) => {
                  e.preventDefault();
                  modalOpen(true);
                  modalType("domain");
                }}
              >
                Domain beállítások
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="dashboard__details__content">
            <div className="dashboard__details__content--block">
              <h5>{domain_name}</h5>
              <h3>{on_trial ? "Demo" : "Korlátlan"}</h3>
            </div>
            {on_trial && (
              <div className="dashboard__details__content--block">
                <div className="dashboard__details__content--rowblock">
                  <span className="span--bold">Elérhető lekérdezések</span>
                  <span className="span--bold">{3 - requests_length} db</span>
                </div>
                <Progressbar max={3} current={requests_length} />
              </div>
            )}
          </div>
        </div>
      </Accordion>

    </div>
  );
};

const Dashboard = () => {
  return (
    <section>
      <div className="dashboard-layout">
        <DashboardDetails />
        <DashboardServices />
      </div>
    </section>
  );
};

export default Dashboard;
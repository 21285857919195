// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import {
  SetRequests
} from "../../data/store/Requests";



// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma lépett fel az adatok lekérdezésekor."]);
    throw Error(response.statusText);
  }
};

// Get Requests for the logged in users domain
const getRequestByDomain = async (domain_id) => {

  await fetch(urls.requestsByDomain + domain_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      // Set returned requests data
      SetRequests(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getRequestByDomain;

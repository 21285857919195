import { proxy } from "valtio";

// STORE
const StoreUser = proxy({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  domain_id: "",
  id: "",
  is_admin: false,
  is_temp_password: false,
});


// Set user data from api data
export const SetUser = (data) => {

  // Keys that need to be stored
  const userDataToStore = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "domain_id",
    "id",
    "is_admin",
    "is_temp_password"
  ];

  // Store the values
  userDataToStore.forEach((key) => {
    StoreUser[key] = data[key];
  });
};


// Update user data when PATCH completes
export const UpdateUser = (data) => {

  // Keys that need to be stored
  const userDataToStore = [
    "first_name",
    "last_name",
    "email",
    "phone",
  ];

  // Store the values
  userDataToStore.forEach((key) => {
    StoreUser[key] = data[key];
  });
};


// Update temp password boolean when PATCH completes
export const UnsetIsTempPassword = () => {
  StoreUser.is_temp_password = false;
};

export default StoreUser;
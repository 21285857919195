import { useState, useEffect } from "react";

const SegmentedControl = ({
  name,
  segments,
  value,
  onChange,
}) => {
  const [activeSegment, setActiveSegment] = useState(value);

  // Handle active segment changes
  useEffect(() => {
    setActiveSegment(value);
  }, [value]);

  return (
    <div className="segmentedcontrol">
      {segments?.map((item, i) => (
        <div
          key={i}
          className={
            `segmentedcontrol__segment
            ${item.value === activeSegment ? "active" : "inactive"}
            ${item.activeColor ? item.activeColor : ""}`
          }
        >
          <input
            type="radio"
            value={item.value}
            id={item.label}
            name={name}
            onChange={onChange}
            checked={item.value === activeSegment}
          />
          <h5>{item.label}</h5>
        </div>
      ))}
    </div>
  );
};

export default SegmentedControl;
// Base URL
const baseURL = "https://api.solarvp.hu/api/";

// Routes
export const users = `${baseURL}v1/users/`;
const domains = `${baseURL}v1/domains/`;
const request = `${users}request/`;

// Used urls in API calls
export const urls = {
  // Health
  health: `https://api.solarvp.hu/api/v1/public/health`,

  // User
  login: `${users}login`,
  profile: `${users}profile`,
  getUsers: `${users}`,
  inviteUser: `${baseURL}v2/user/invite`,  // Invite user V2
  convertUser: `${baseURL}v2/user/`,  // Convert user to admin or convert admin to user V2

  // Password
  password: `${users}profile/password`,
  forgotPassword: `${users}profile/forgot`,

  // Domain
  domain: `${domains}`, // Needs {domain_id}

  // Request
  requestsByDomain: `${request}domain/`, // Needs {domain_id}
};
// Google analitics tracking
import ReactGA from "react-ga4";

// Base URL
const gaMeasurementID = "G-QK3TSW75QF";

// Initialize Google Analytics
export const GaInit = () => {
  ReactGA.initialize(gaMeasurementID, {
    debug: true,
  });
};

// Login event
export const GaEventLogin = () => {
  ReactGA.event({
    category: 'User',
    action: 'User Logged In'
  });
};
import React from "react";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <header>
      <Navbar />
    </header>
  )
}

export default Header

// Cookies Import
import Cookies from "universal-cookie";

// Initialize cookies
const cookies = new Cookies();

// Domain option
const domain = process.env.NODE_ENV === "development" ? "localhost" : ".solarvp.hu"


// Token
export const cookieSetToken = (token) => {
  cookies.set("userToken", token, { domain: domain });
};
export const cookieGetToken = () => {
  return cookies.get("userToken", { domain: domain });
};
export const cookieRemoveToken = () => {
  cookies.remove("userToken", { domain: domain });
};

// RememberMe
export const cookieSetRememberMe = (email, pass) => {
  cookies.set("rememberMe", { email: email, password: pass }, { domain: domain });
};
export const cookieGetRememberMe = () => {
  return cookies.get("rememberMe", { domain: domain });
};
export const cookieRemoveRememberMe = () => {
  cookies.remove("rememberMe", { domain: domain });
};

// TempPassword
export const cookieSetTempPassword = (temppass) => {
  cookies.set("tempPassword", temppass, { domain: domain });
};
export const cookieGetTempPassword = () => {
  return cookies.get("tempPassword", { domain: domain });
};
export const cookieRemoveTempPassword = () => {
  cookies.remove("tempPassword", { domain: domain });
};
// Component imports
import Icons from "../../assets/svg/Icons";
import Badge from "../Badge/Badge";

const CardServices = ({
  title,
  dataPurchased,
  myKey,
  lastQuery,
  link,
  img,
  badgeStatus,
}) => {
  return (
    <a href={link}>
      <div className="card" key={myKey}>
        
        <div className="card__img">
          <img src={img} alt={title} />
        </div>

        <div className="card__content">
          <h3>{title}</h3>
          <ul className="card__content-details">       
            <li>
              <span>Megvásárolt téradatok</span>
              <div className="meta">
                <span className="span--bold">{dataPurchased} db</span>
              </div>
            </li>  
            <li>
              <span>Utolsó lekérdezés</span>
              <div className="meta">
                <span className="span--bold">{lastQuery || "-"}</span>
                <Badge status={badgeStatus} type="request" />
              </div>
            </li>
          </ul>
        </div>

        <div className="card__link">
          <Icons.ArrowForward />
        </div>

      </div>
    </a>
  );
};

export default CardServices;

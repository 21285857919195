import React from "react";

const Badge = ({ status, type }) => {
  let text, color;

  if (type === "request") {
    switch (true) {
      case status === "Pending":
        text = "Feldolgozás alatt";
        color = "yellow";
        break;
      case status === "Available":
        text = "Elérhető";
        color = "green";
        break;
      case status === "Denied":
        text = "Nem teljesíthető";
        color = "red";
        break;
      default:
        break;
    }
  }

  return (
    <span className={`badge ${color}`}>{text}</span>
  );
};

export default Badge;

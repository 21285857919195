const Checkbox = ({ label, name, value, onChange, checked }) => {
  return (
    <div className="checkbox">
      <label name={name}>
        <input
          name={name}
          type="checkbox"
          value={value}
          onChange={onChange}
          checked={checked}
        />
        <span className="checkbox__checkmark"></span>
        {label}
      </label>
    </div>
  );
};
export default Checkbox;

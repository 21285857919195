// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken,
  cookieGetRememberMe,
  cookieSetRememberMe,
  cookieGetTempPassword,
  cookieRemoveTempPassword
} from "../cookie";
// Store
import { UnsetIsTempPassword } from "../../data/store/User";
import patchProfile from "../user/patchProfile";


// Check error
const CheckError = (response, message, profileBody) => {
  if (response.status === 200) {
    if (!profileBody) message(["success", "Jelszó sikeresen frissítve!"]);
    return response.json();
  } else {
    // Send error message to user
    if (!profileBody) message(['error', "Probléma történt a jelszó frissítése közben!"]);
    throw Error(response.statusText);
  }
};

// Forgotten password
const patchPassword = async (body, cookie, message, profileBody) => {
  await fetch(urls.password, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: body,
  })
    .then((response) => CheckError(response, message, profileBody))
    .then(() => {

      // If remember me saved in cookie,
      // then update password in cookie also
      if (cookieGetRememberMe()) {
        cookieSetRememberMe(cookie.email, cookie.password);
      }

      // If temp password was updated,
      // then remove the tempPassword cookie
      if (cookieGetTempPassword()) {
        UnsetIsTempPassword();
        cookieRemoveTempPassword();
      }

      // Patch user data if it's required 
      if (profileBody) patchProfile(profileBody, message)
    })
    .catch((error) => {
      console.log(error);
    });
};

export default patchPassword;

import { useCallback, useContext, useState, createContext } from "react";
import Button from "../Button/Button";
import Icons from "../../assets/svg/Icons";
import Row from "../Layout/Row";

const ToastContext = createContext();

export default ToastContext;

/* 
// Example of using toast //

// Info toast //
addToast([
  // Style of toast
  "success", 
  // Short message of toast
  "Sikeres elutasítás!"
])

// Action toast //
addToast([
  // Style of toast
  "error",
  // Short text of toast
  "Biztos kívánja törölni a felhasználót?",
  // Type of toast
  "action",
  // Long text of toast
  "A törlése viszavonhatatlan művelet, azonban ezen felhasználó igényei továbbra is elérhetőek maradnak a domainen belül.",
  // Action of toast
  deleteScopedUserPassed,
  // Action button of toast
  "Törlés megerősítése",
]); */

export const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (toast) => {
      if (toasts.length === 0) {
        setToasts((toasts) => [...toasts, toast]);
        if (toast[2] !== "action") {
          setTimeout(() => setToasts((toasts) => toasts.slice(1)), 3000);
        }
      }
    },
    [setToasts, toasts]
  );

  const removeToast = () => {
    setToasts((toasts) => toasts.slice(1));
  };

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      {toasts.map((message, index) => {
        return (
          <div
            className={`toast ${message[2] !== "action" ? "show" : "fix"} ${message[0]
              }`}
            key={index}
          >
            <div className="toast-content">
              <Row>
                <p>{message[1]}</p>
                <button onClick={() => removeToast()}>
                  <Icons.HamburgerClose />
                </button>
              </Row>
              {message[2] === "action" && (
                <>
                  <p className="toast-content-body-message">{message[3]}</p>
                  <Button
                    buttonType="small"
                    onClickEvent={() => {
                      removeToast();
                      message[4]();
                    }}
                  >
                    {message[5]}
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      })}
    </ToastContext.Provider>
  );
};

export function useToastContext() {
  return useContext(ToastContext);
}

// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import {
  SetUserLoggedIn
} from "../../data/store/Global";
import {
  SetUser
} from "../../data/store/User";

// Init API
import getInit from "../init";
import { GaEventLogin } from "../ga";


// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Set user logged out
    SetUserLoggedIn(false);
    throw Error(response.statusText);
  }
};

// Get Profile
const getProfile = async () => {

  await fetch(urls.profile, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {

      // GA send login event
      GaEventLogin();

      // Set user logged in
      SetUserLoggedIn(true);
      
      // Set returned user data
      SetUser(actualData);
      
      // Init
      getInit(actualData.domain_id);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getProfile;

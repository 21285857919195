// React
import { useEffect, useState } from "react";
// React router
import { NavLink } from "react-router-dom";
// Components import
import Input from "../../components/Input/Input";
import InputPassword from "../../components/Input/InputPassword";
import Icons from "../../assets/svg/Icons";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Input/Checkbox";
// Toast import
import { useToastContext } from "../../components/Toast/Toast";
// API import
import Login from "../../api/user/login";
// Cookie import
import { cookieGetRememberMe } from "../../api/cookie";


const LoginPage = () => {
  const [ loginFields, setLoginFields ] = useState({
    email: "",
    password: "",
    rememberMe: false
  })
  const addToast = useToastContext();

  // Load RememberMe
  useEffect(() => {
    const cookieRememberMe = cookieGetRememberMe();
    if (cookieRememberMe) {
      setLoginFields({
        email: cookieRememberMe.email,
        password: cookieRememberMe.password,
        rememberMe: true
      });
    }
  }, []);

  // Handle input change
  const handleInput = (e) => {
    setLoginFields(prevLoginFields => {
      const {name, value, type, checked} = e.target
      return {
        ...prevLoginFields,
        [name]: type === "checkbox" ? checked : value
      }
    })
  }

  // Handle form submition
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Create request body
    let body = {
      email: loginFields.email,
      password: loginFields.password,
      rememberMe: loginFields.rememberMe,
    };
    // Call Login API
    Login(body, addToast);
  };

  return (
    <div className="login-page">
      <div className="login-page__container">
        <a href="https://solarvp.hu/">
          <Icons.Logo />
        </a>
        <div className="login-page__container-action">
          <form onSubmit={handleSubmit}>
            
            <Input
              label="E-mail cím"
              value={loginFields.email}
              onChange={handleInput}
              type="text"
              name="email"
              required={true}
            />
            <InputPassword
              label="Jelszó"
              value={loginFields.password}
              onChange={handleInput}
              name="password"
              required={true}
            />
            <Button actionType="submit">Belépés</Button>
            
            <div className="login-page__container-action__bottom">
              <Checkbox
                label="Jegyezzen meg"
                name="rememberMe"
                value={loginFields.rememberMe}
                onChange={handleInput}
                checked={loginFields.rememberMe}
              />
              <NavLink to="/forgotten">
                <Button asLink={"true"}>Elfelejtett jelszó?</Button>
              </NavLink>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

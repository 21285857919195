// React
import { useState } from "react";
// React router
import { NavLink } from "react-router-dom";
// Components import
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Icons from "../../assets/svg/Icons";
import { useToastContext } from "../../components/Toast/Toast";
// Store
import { useSnapshot } from "valtio";
import StoreGlobal, { SetForgottenSent } from "../../data/store/Global";
// API
import postForgotPassword from "../../api/password/postForgotPassword";
import Login from "../../api/user/login";


const ForgottenPasswordPage = () => {
  const { forgottenSent } = useSnapshot(StoreGlobal)
  const [forgotFields, setForgotFields] = useState({
    email: "",
    tempPass: "",
  })
  const addToast = useToastContext();

  // Handle input change
  const handleInput = (e) => {
    setForgotFields(prevForgotFields => {
      const {name, value, type, checked} = e.target
      return {
        ...prevForgotFields,
        [name]: type === "checkbox" ? checked : value
      }
    })
  }

  // Handle form submition
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!forgottenSent) {
      // Create request body
      let body = {
        email: forgotFields.email,
      };
      // Call forgot password API
      postForgotPassword(body, addToast);
    } else {
      let body = {
        email: forgotFields.email,
        password: forgotFields.tempPass,
      };
      // Call login API
      Login(body, addToast);
    }
  };

  return (
    <div className="forgotten-password-page">
      <div className="forgotten-password-page__container">
        <a href="https://solarvp.hu/">
          <Icons.Logo />
        </a>
        <div className="forgotten-password-page__container-action">
          
          {!forgottenSent ? (
            <>
              <div className="forgotten-password-page__container-action__head">
                <h4>Elfelejtett jelszó</h4>
                <p>Elfelejtett jelszava módosításához kérjük adja meg a szolgáltatáshoz használt e-mail címét.</p>
              </div>
              <form onSubmit={handleSubmit}>
                <Input
                  label="E-mail cím"
                  value={forgotFields.email}
                  onChange={handleInput}
                  type="text"
                  name="email"
                  required={true}
                />
                <Button actionType="submit">Küldés</Button>
              </form>
              <div className="forgotten-password-page__container-action__bottom">
                <NavLink to="/">
                  <Button asLink={"true"}>Mégsem</Button>
                </NavLink>
              </div>
            </>
          ) : (
            <>
              <div className="forgotten-password-page__container-action__head">
                <h4>Küldtünk egy e-mailt</h4>
                <p>A továbblépéhez kérjük adja meg ideiglenes jelszavát amit elküldtünk a(z) {forgotFields.email} címre.</p>
              </div>
              <form onSubmit={handleSubmit}>
                <Input
                  label="Ideiglenes jelszava"
                  value={forgotFields.tempPass}
                  onChange={handleInput}
                  type="text"
                  name="tempPass"
                  required={true}
                />
                <Button actionType="submit">Tovább</Button>
              </form>
              <div className="forgotten-password-page__container-action__bottom">
                <Button
                  onClickEvent={() => SetForgottenSent(false)}
                  asLink={"true"}
                >
                  Vissza
                </Button>
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default ForgottenPasswordPage;

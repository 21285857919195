// React
import { createContext, useState, useEffect } from "react";
// React router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Layout
import Container from "../Layout/Container";
import Footer from "../Layout/Footer";
// Pages
import Dashboard from "../../pages/dashboard/Dashboard";
import Requests from "../../pages/requests/Requests";
import Error from "../../pages/error/Error";
import Blank from "../../pages/blank/Blank";
import LoginPage from "../../pages/login/Login";
import ForgottenPasswordPage from "../../pages/forgotten/Forgotten";
// Modal
import ModalManger from "../Modal/ModalManager";
// Toast
import { ToastContextProvider } from "../Toast/Toast";

// Valtio and Store
import { useSnapshot } from "valtio";
import StoreGlobal, { SetUserLoggedIn } from "../../data/store/Global";

// Cookie import
import { cookieGetToken } from "../../api/cookie";

// API call
import getProfile from "../../api/user/getProfile";

// Modal Context
export const ModalContext = createContext();

const MainRouter = (props) => {
  const { userLoggedIn } = useSnapshot(StoreGlobal);
  const { is_temp_password, first_name, last_name, phone } = useSnapshot(
    StoreGlobal.user
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");

  // Initial Effect checking login state
  useEffect(() => {
    if (cookieGetToken()) {
      getProfile();
      SetUserLoggedIn(true);
    }
  }, []);

  // Temporary password change modal handling
  useEffect(() => {
    if (
      is_temp_password &&
      first_name !== "felhasználó" &&
      last_name !== "Meghívott" &&
      phone !== ""
    ) {
      setModalType("temppass");
      setIsModalOpen(true);
    } else if (
      is_temp_password &&
      first_name === "felhasználó" &&
      last_name === "Meghívott" &&
      phone === ""
    ) {
      setModalType("tempemail");
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [is_temp_password, first_name, last_name, phone]);

  // Modal handlin effect
  useEffect(() => {
    isModalOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [isModalOpen]);

  // Open modal function
  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  };

  return userLoggedIn ? (
    <ToastContextProvider>
      <ModalContext.Provider
        value={{ modalOpen: setIsModalOpen, modalType: setModalType }}
      >
        <ModalManger
          type={modalType}
          isModalOpen={isModalOpen}
          setIsOpen={isModalOpen}
        />
        <Router>
          {props.children}
          <Container>
            <Routes>
              <Route path="/">
                <Route index element={<Dashboard />} />
                <Route path="requests" element={<Requests />} />
                <Route path="blank" element={<Blank />} />
                <Route path="*" element={<Error />} />
              </Route>
            </Routes>
          </Container>
          <Footer />
        </Router>
      </ModalContext.Provider>
    </ToastContextProvider>
  ) : (
    <ToastContextProvider>
      <Router>
        <Routes>
          <Route path="/">
            <Route index element={<LoginPage />} />
            <Route path="forgotten" element={<ForgottenPasswordPage />} />
            <Route path="*" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </ToastContextProvider>
  );
};

export default MainRouter;

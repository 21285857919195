// React
import { useState, useEffect } from "react";
// React Data Table
import DataTable from 'react-data-table-component';
import { tableBreakpoints, columWidth, paginationComponentOptions } from "../Table";
// Component imports
import TableLoader from "../TableLoader";
import TableNoResults from "../TableNoResults";
import Badge from "../../Badge/Badge";
import TableArrow from "../TableArrow";


const TableRequests = ({data}) => {
  const [loading, setLoading] = useState(true);

  const colums = [
    {
      name: "Azonosító",
      selector: (row) => `SVP-${row.id}`,
      sortable: true,
      width: columWidth.large,
      style: {
        fontWeight: 500,
      },
      hide: tableBreakpoints.TL,
    },
    {
      id: 'date',
      name: "Dátum",
      selector: (row) => row.created_at,
      sortable: true,
      width: columWidth.dateTime,
      hide: tableBreakpoints.DS,
    },
    {
      name: "Cím",
      selector: (row) => row.resolved_addr,
      sortable: true,
    },
    {
      name: "Típus",
      selector: (row) => row.building_type,
      sortable: true,
      width: columWidth.normal,
      hide: tableBreakpoints.DL,
    },
    {
      name: "Státusz",
      selector: (row) => <Badge status={row.status} type="request"/>,
      sortable: true,
      width: columWidth.normal,
    },
    {
      cell: (row) => <TableArrow id={row.id}/>,
      sortable: true,
      width: columWidth.arrow,
    }
  ];

  // Loading state handling
  useEffect(() => {
    if(data.length > 1){
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="datatable__table">
      <DataTable
        data={data}
        columns={colums}
        onRowClicked={(row) => {window.location.assign('http://map.solarvp.hu/?svp='+row.id);}}
        progressPending={loading}
        progressComponent={<TableLoader/>}
        defaultSortFieldId="date"
        defaultSortAsc={false}
        noDataComponent={<TableNoResults text="Nincsenek a keresésnek megfelelő adatigényei"/>}
        paginationPerPage={30}
        paginationComponentOptions={paginationComponentOptions}
        responsive
      />
    </div>
  );
}

export default TableRequests;
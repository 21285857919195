// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import {
  SetUsers
} from "../../data/store/Domain";

// Check error
const CheckError = (response) => {
  if (response.status === 200) {
    return response.json();
  } else {
    throw Error(response.statusText);
  }
};

// Get Domain details
const getDomainUsers = async () => {

  await fetch(urls.getUsers, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response))
    .then((actualData) => {
      // Store users list
      SetUsers(actualData);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getDomainUsers;

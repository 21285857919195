// Components import
import Icons from "../../assets/svg/Icons";
import Button from "../Button/Button";


const TableEmpty = (props) => {
  return (
    <div className="datatable__empty">
      <div className="datatable__empty__icon">
        {props.icon || <Icons.SadPin /> }
      </div>
      <div className="datatable__empty__content">
        <h4>{props.headline}</h4>
        <p>{props.message}</p>
      </div>
      <a href="https://map.solarvp.hu/">
        <Button>Tovább a térképhez</Button>
      </a>
    </div>
  );
};

export default TableEmpty;
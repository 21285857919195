// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken,
} from "../cookie";
// Store
import { UpdateDomain } from "../../data/store/Domain";


// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    message(["success", "Domain beállítások sikeresen frissítve."]);
    return response.json();
  } else {
    // Send error message to user
    message(['error', "Probléma történt a domain beállítások frissítése közben."]);
    throw Error(response.statusText);
  }
};

// Patch Domain
const patchDomain = async (domain_id, body, message) => {
  await fetch(urls.domain + domain_id, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
  .then((response) => CheckError(response, message))
    .then(() => {
      // Update Store
      UpdateDomain(body);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default patchDomain;

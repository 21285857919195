// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken,
} from "../cookie";

// Store
import { UpdateUser } from "../../data/store/User";


// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    message(['success', "A személyes adatok frissítve lettek."]);
    return response.json();
  } else {
    // Send error message to user
    message(['error', "Probléma történt a személyes adatok frissítése közben."]);
    throw Error(response.statusText);
  }
};

// Patch Profile
const patchProfile = async (body, message) => {
  await fetch(urls.profile, {
    method: "PATCH",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
    body: JSON.stringify(body),
  })
    .then((response) => CheckError(response, message))
    .then(() => {
      // Update Store
      UpdateUser(body);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default patchProfile;

import React from "react";
import CardPackage from "../../Cards/CardPackage";
import Icons from "../../../assets/svg/Icons";
import Row from "../../Layout/Row";
import Modal from "../Modal";

const data = [
  {
    active: false,
    title: "Pay as you go",
    list: [
      "0 Ft havi alapdíj",
      "1 felhasználói fiók",
      "Max. 72 órás adatszolgáltatás",
      "Email támogatás",
    ],
    price: "3 500",
    priceUnit: "Ft",
    subPrice: "lekérdezésenként",
  },
  {
    active: true,
    title: "Starter",
    list: [
      "20 db kedvezményes lekérdezés/hó",
      "3 felhasználói fiók",
      "Max. 72 órás adatszolgáltatás",
      "Email támogatás",
      "20 db kedvezményes lekérdezés felett 3000Ft/lekérdezés",
    ],
    price: "49 990",
    priceUnit: "Ft/hó",
    subPrice: "2500 Ft/lekérdezés",
  },
  {
    active: false,
    title: "Business",
    list: [
      "50 db kedvezményes lekérdezés/hó",
      "10 felhasználói fiók",
      "Ipari területek lekérdezése",
      "Max. 48 órás adatszolgáltatás",
      "Email támogatás",
      "Telefonos támogatás",
      "50 db kedvezményes lekérdezés felett 2500Ft/lekérdezés",
    ],
    price: "99 990",
    priceUnit: "Ft/hó",
    subPrice: "2000 Ft/lekérdezés",
  },
  {
    active: false,
    title: "Enterprise",
    list: [
      "Korlátlan kedvezményes lekérdezés/hó*",
      "Korlátlan felhasználói fiók",
      "Ipari területek lekérdezése",
      "Max. 36 órás adatszolgáltatás",
      "Email támogatás",
      "Dedikált telefonos támogatás",
    ],
    price: "Egyéni",
    priceUnit: "",
    subPrice: "*Árazás megegyezés szerint",
  },
];

const ModalPackage = ({ isModalOpen }) => {
  return (
    <Modal
      isModalOpen={isModalOpen}
      title={"Csomagok"}
      hasFooter={false}
      xxlModal={true}
    >
      <div className="modal__content-warning">
        <Icons.Information />
        <p>
          Önnek nincs jogosultsága csomag módosítás kezdeményezésére. Módosítási
          igénye esetén kérjük vegye fel a kapcsolatot szervezete
          adminisztrátorával.
        </p>
      </div>
      <Row gap={["xxs"]}>
        {data.map((element, index) => {
          return (
            <div className="--col-xs-3" key={index}>
              <CardPackage
                active={element.active}
                title={element.title}
                list={element.list}
                price={element.price}
                subPrice={element.subPrice}
                priceUnit={element.priceUnit}
              />
            </div>
          );
        })}
      </Row>
      <div className="modal__content-subtext">
        <p>
          Csomag váltásra csak a számlázási időszakok között van lehetőség. A
          fel nem használt kedvezményes lekérdezések a számlázási időszakok és
          csomagok között tovább nem görgethetőek. Csomagjainkkal kapcsolatos
          további kérdése esetén kérjük vegye fel velünk a kapcsolatot az{" "}
          <a href="mailto:info@solarvp.hu">info@solarvp.hu</a> email címen.
        </p>
      </div>
    </Modal>
  );
};

export default ModalPackage;

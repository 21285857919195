// API URL
import { urls } from "../urls";
// Cookie
import { cookieGetToken } from "../cookie";

// Check error
const CheckError = (response, message, newBody) => {
  if (response.status === 200) {
    message(["success", "A felhasználó meghívása került."]);

    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma a felhasználó meghívása közben."]);
    throw Error(response.statusText);
  }
};

// getProfile
const inviteNewUser = async (body, message, email, setFormData) => {
  let newBody = { email: email };

  await fetch(urls.inviteUser, {
    method: "POST",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then((response) => CheckError(response, message, newBody))
    .then(() => {
      // Clear fields
      setFormData({inviteEmail: ""});
    })
    .catch((error) => {
      console.log(error);
    });
};

export default inviteNewUser;

import React from "react";

import ModalProfile from "./Modals/ModalProfile";
import ModalUsers from "./Modals/ModalUsers";
import ModalDomain from "./Modals/ModalDomain";
import ModalPackage from "./Modals/ModalPackage";
import ModalTempPassword from "./Modals/ModalTempPassword";
import ModalTempEmail from "./Modals/ModalTempEmail";

const ModalManager = ({ isModalOpen, type}) => {
  switch (true) {
    case type === "profile":
      return <ModalProfile isModalOpen={isModalOpen}/>;
    case type === "users":
      return <ModalUsers isModalOpen={isModalOpen}/>;
    case type === "domain":
      return <ModalDomain isModalOpen={isModalOpen}/>;
    case type === "packages":
      return <ModalPackage isModalOpen={isModalOpen}/>;
    case type === "temppass":
      return <ModalTempPassword isModalOpen={isModalOpen}/>;
    case type === "tempemail":
      return <ModalTempEmail isModalOpen={isModalOpen}/>;
    default:
      break;
  }
};

export default ModalManager;

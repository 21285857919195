// API URL
import { urls } from "../urls";
// Cookie
import {
  cookieGetToken
} from "../cookie";
// Store
import {
  SetDomain
} from "../../data/store/Domain";
import getDomainUsers from "./getDomainUsers";



// Check error
const CheckError = (response, message) => {
  if (response.status === 200) {
    return response.json();
  } else {
    // Send error message to user
    message(["error", "Probléma lépett fel a domain adatok lekérdezésekor."]);
    throw Error(response.statusText);
  }
};

// Get Domain details
const getDomain = async (domain_id, message) => {

  await fetch(urls.domain + domain_id, {
    method: "GET",
    headers: {
      accept: "application/json",
      Authorization: "Bearer " + cookieGetToken(),
    },
  })
    .then((response) => CheckError(response, message))
    .then((actualData) => {
      // Set returned requests data
      SetDomain(actualData);
      getDomainUsers();
    })
    .catch((error) => {
      console.log(error);
    });
};

export default getDomain;

// React
import { useState, useEffect } from "react";

// Components
import Modal from "../Modal";
import InputPassword from "../../Input/InputPassword";
import Button from "../../Button/Button";
import Icons from "../../../assets/svg/Icons";

// Toast
import { useToastContext } from "../../Toast/Toast";

// Store
import { useSnapshot } from "valtio";
import StoreGlobal from "../../../data/store/Global";

// API import
import patchPassword from "../../../api/password/patchPassword";

// Cookie
import { cookieGetTempPassword } from "../../../api/cookie";



const ModalTempPassword = ({ isModalOpen }) => {
  const { email } = useSnapshot(StoreGlobal.user);
  const [passwordError, setPasswordError] = useState();
  const addToast = useToastContext();

  // Form Data
  const [formData, setFormData] = useState({
    tempPass: cookieGetTempPassword(),
    newPass: "",
    newPassAgain: "",
  });

  // Effect to handle form changes
  useEffect(() => {
    // Check if passwords are matching
    if (formData.newPassAgain) {
      if (formData.newPass === formData.newPassAgain) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
      }
    } else {
      setPasswordError(false);
    }
  }, [formData]);

  // Handle input change
  const handleInput = (e) => {
    setFormData(prevData => {
      const {name, value, type, checked} = e.target
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value
      }
    });
  };

  // Handle form submition
  const handleSubmit = (e) => {
    e.preventDefault();

    // Update pasword if fields are filled
    if (formData.newPass && formData.newPass === formData.newPassAgain) {

      if (formData.newPass === formData.tempPass) {
        // Toast user if temp password entered as new password
        addToast(["error", "Új jelszava nem egyezhet meg ideiglenes jelszavával!"]);
      } else {
        // Create body
        let passwordBody = JSON.stringify({
          old_password: formData.tempPass,
          new_password: formData.newPass
        });
        // Create cookie update body
        let cookieBody = {
          email: email,
          password: formData.newPass
        };
        // Initialize API
        patchPassword(passwordBody, cookieBody, addToast);
      }
    }
  };

  return (
    <Modal isModalOpen={isModalOpen}>
      <form id="modalForm" onSubmit={handleSubmit}>
        
        <div className="modal__content__heading">
          <div className="modal__content__heading-icon">
            <Icons.Password />
          </div>
          <div className="modal__content__heading-text">
            <h2>Módosítsa jelszavát</h2>
            <p>Kérjük adjon meg egy új jelszót ideiglenes jelszavának megváltoztatásához.</p>
          </div>
        </div>
        
        <div className="modal__content__section">
          <div className="modal__content__section__group">
            <InputPassword
              label={"Új jelszó"}
              name={"newPass"}
              onChange={handleInput}
              value={formData.newPass}
              error={passwordError}
            />
            <InputPassword
              label={"Új jelszó mégegyszer"}
              name={"newPassAgain"}
              onChange={handleInput}
              value={formData.newPassAgain}
              error={passwordError}
            />
          </div>
        </div>

        <div className="modal__content__footer">
          <Button actionType="submit">Mentés</Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalTempPassword;

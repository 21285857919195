// React
import { useState, useEffect, useContext } from "react";
// React Router
import { NavLink } from "react-router-dom";
// Component imports
import { ModalContext } from "../Router/Router";
import Icons from "../../assets/svg/Icons";
import ContainerFluid from "./ContainerFluid";
// Cookie import
import { cookieRemoveToken } from "../../api/cookie";
// Store
import { useSnapshot } from "valtio";
import StoreGlobal from "../../data/store/Global";


const NavLinks = [
  {
    text: "Dashboard",
    link: "/",
  },
  {
    text: "Téradataim",
    link: "/requests",
  },
];

const ProfileLinks = [
  {
    text: "Profil",
    link: "profile",
    adminOnly: false,
  },
  {
    text: "Felhasználók",
    link: "users",
    adminOnly: true,
  },
  {
    text: "Domain",
    link: "domain",
    adminOnly: true,
  },
];

const ProfileMenu = () => {
  const { is_admin } = useSnapshot(StoreGlobal.user)
  
  const handleLogout = (e) => {
    e.preventDefault();
    
    // Remove usertoken from cookie
    cookieRemoveToken();

    // Reload window
    window.location.reload();
  };

  const { modalOpen, modalType } = useContext(ModalContext);
  return (
    <div className="header__content__profile-menu">
      <ul>
        {ProfileLinks.map((element, index) => {
          if (!element.adminOnly || (element.adminOnly && is_admin) ) {
            return (
              <li key={index}>
                <NavLink
                  onClick={(e) => {
                    e.preventDefault();
                    modalOpen(true);
                    modalType(element.link);
                  }}
                  to={element.link}
                >
                  {element.text}
                </NavLink>
              </li>
            );
          } else {
            return null;
          }
        })}
        <hr />
        <li>
          <a href="/" onClick={(e) => handleLogout(e)}>
            Kijelentkezés
            <Icons.ArrowForward />
          </a>
        </li>
      </ul>
    </div>
  );
};

const NavbarOverlay = ({ menuOpen, setMenuOpen }) => {
  return (
    <div
      className={
        menuOpen
          ? "header__mobile-overlay header__mobile-overlay-active"
          : "header__mobile-overlay"
      }
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    ></div>
  );
};

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  useEffect(() => {
    menuOpen ? OpenModalFunction() : (document.body.style.overflow = "");
    return () => {};
  }, [menuOpen]);

  const OpenModalFunction = () => {
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      <ContainerFluid>
        <div className="header__content">
          <NavbarOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <div className="header__content__left">
            <div className="header__content__left__logo">
              <a href="https://solarvp.hu/">
                <Icons.Logo />
              </a>
            </div>
            <NavLink to="/">
              <h3 className="header__content__left__title">Személyes fiók</h3>
            </NavLink>
          </div>
          <nav
            className={`header__content__nav ${
              menuOpen ? "header__content__nav-menu-open" : ""
            }`}
          >
            <ul>
              <div className="header__content__nav-close">
                <button
                  className="header__content__nav-close-button"
                  onClick={() => {
                    setMenuOpen(!menuOpen);
                  }}
                >
                  <Icons.HamburgerClose />
                </button>
              </div>
              {NavLinks.map((element, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={element.link}
                      onClick={() => setMenuOpen(false)}
                    >
                      {element.text}
                    </NavLink>
                  </li>
                );
              })}
              <li>
                <button
                  className="header__content-profile-button"
                  onClick={() => {
                    setProfileMenuOpen(!profileMenuOpen);
                  }}
                >
                  <Icons.User />
                  <Icons.ArrowDropDown />
                </button>
                {profileMenuOpen ? <ProfileMenu /> : ""}
              </li>
            </ul>
            <div className="header__content__nav-mobile-menu">
              <ProfileMenu />
            </div>
          </nav>
          <div className="header__content__toggle">
            <button
              className="header__content__toggle__nav-hamburger-button"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <Icons.Hamburger />
            </button>
          </div>
        </div>
      </ContainerFluid>
    </>
  );
};

export default Navbar;

import Icons from "../../assets/svg/Icons"

const Footer = () => {
  return (
    <footer>
      <div className="footer__legal">
        <span>{new Date().getFullYear()} - © Solar Viewpoint Kft.</span>
      </div>
      <ul className="footer__links">
        <li>
          <a
            href="https://solarvp.hu/ASZF-SolarVP_latest.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <Icons.FileDefault />
            <span className="span--bold">Általános Szerződési Feltételek</span>
          </a>
        </li>
        <li>
          <a
            href="mailto:info@solarvp.hu"
            target="_blank"
            rel="noreferrer"
          >
            <Icons.Help />
            <span className="span--bold">Segítségkérés</span>
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer

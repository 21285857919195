// API URL
import { users } from "../urls";
// Cookie
import {
    cookieGetToken,
} from "../cookie";
// Check error
const CheckError = (response, message) => {
    if (response.status === 200) {
        message(['success', "Felhasználó sikeresen törölve."]);
        return response.json();
    } else {
        // Send error message to user
        message(['error', "Probléma történt."]);
        throw Error(response.statusText);
    }
};

// Patch Profile
const deleteUser = async (id, message) => {
    await fetch(users + id, {
        method: "DELETE",
        headers: {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookieGetToken(),
        }
    })
        .then((response) => CheckError(response, message))
        .then((actualData) => console.log(actualData))
        .catch((error) => {
            console.log(error);
        });
};

export default deleteUser;
